// TO DO: Fix types for Drip Campaigns and improve the ICampaignGql interface
import { ApolloClient } from '@apollo/client'
import { camelCase } from 'lodash'
import type { ApolloContext } from '~/services/types/Gql'
import getNotificationsSettingsPerDevice from '@/graphql/notifications/getNotificationsSettingsPerDevice.gql'
import createUpdateNotificationSettings from '@/graphql/notifications/createNotificationSettings.gql'

interface INotificationsGql {
  $apollo: ApolloClient<any>
  createUpdateNotificationsSettings: (device: string, setting: Array<any>) => Promise<any>
  getNotificationsSettings: (deviceToken: string, tenantId: string) => Promise<any>
}
export default class NotificationsGql implements INotificationsGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  getNotificationsSettings (deviceToken: string, tenantId: string) {
    const notificationOptions = [
      {
        notificationOption: 'campaign_status',
        receiveNotification: true
      },
      {
        notificationOption: 'incoming_reply',
        receiveNotification: true
      },
      {
        notificationOption: 'new_conversation',
        receiveNotification: true
      }
    ]
    return this.$apollo.query({
      query: getNotificationsSettingsPerDevice,
      variables: {
        deviceToken,
        tenantId
      }
    }).then(result => result?.data?.inboxNotificationSettings?.map(inboxSettings => ({
      inbox: inboxSettings.inbox,
      notificationOptions: notificationOptions.reduce((acc, option) => {
        const inboxSettingOptionFound = inboxSettings.notificationOptions.find(inboxSettingOption => inboxSettingOption.notificationOption === option.notificationOption)
        acc[camelCase(option.notificationOption)] = inboxSettingOptionFound?.receiveNotification === undefined ? option.receiveNotification : inboxSettingOptionFound?.receiveNotification
        return acc
      }, {})
    })))
  }

  createUpdateNotificationsSettings (device: string, settings: Array<any>) {
    return this.$apollo.mutate({
      mutation: createUpdateNotificationSettings,
      variables: {
        deviceToken: device,
        settings
      }
    }).then(result => result.data.notificationSettingsCreateUpdate)
  }
}
